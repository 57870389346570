import React from 'react'
import Img from 'gatsby-image'
import styles from './image-carousel.module.css'
import { CSSTransitionGroup } from 'react-transition-group'
import { addCloudinaryTransformations } from '../lib/util'
const cloudinaryTransforms = 'c_fit,e_sharpen:200,h_1440,w_1920,q_80'

export default class ImageCarouselTransformGroup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      imageIndex: 0
    }
  }

  componentDidMount() {
    const { images } = this.props
    setInterval(() => {
      this.setState({ imageIndex: (this.state.imageIndex + 1) % images.length })
    }, 5000)
  }

  render() {
    const { images, onImageClicked } = this.props
    const { imageIndex } = this.state

    return (
      <div className={styles.carouselContainer}>
        {imageIndex < images.length &&
          <CSSTransitionGroup
            transitionName="carousel"
            transitionEnterTimeout={2000}
            transitionLeaveTimeout={2000}
            transitionEnter={true}
            transitionLeave={true}>
            <div key={imageIndex} className={styles.imageItem} onClick={e => {
                if(onImageClicked)
                  onImageClicked(images[imageIndex], imageIndex)
              }} >
              {images[imageIndex].fluid &&
                <Img
                  fluid={images[imageIndex].fluid}
                  className={styles.imageCarousel}
                  />
              }
              {images[imageIndex].secure_url &&
                <img
                  src={addCloudinaryTransformations(images[imageIndex].secure_url, cloudinaryTransforms)}
                  className={styles.imageCarousel}
                  />
              }
              
            </div>
          </CSSTransitionGroup>
        }
        
      </div>
    )
  }

}


/*

{images
            .filter((media, index) => index === imageIndex)
            .map(media => (
            <div
              key={media.fluid.src} 
              style={{ 
                backgroundImage: `url(${media.fluid.src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }} 
              className={styles.imageCarousel} />
          ))}

<div
            key={imageIndex} 
            style={{ 
              backgroundImage: `url(${images[imageIndex].fluid.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }} 
            className={styles.imageCarousel} />

<Img
              key={media.id} 
              fluid={media.fluid}
              className={styles.imageCarousel} />

<div
  key={media.id} 
  style={{ 
    backgroundImage: `url(${media.fluid.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }} 
  className={styles.imageCarousel} />

*/