import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Container from './container'
import styles from './lightbox.module.css'
import Navigation from './navigation'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { addCloudinaryTransformPreset } from '../lib/util'
import AsyncImage from './async-image'

export default class Lightbox extends React.Component {

  render() {
    const { assets, currentIndex, onClose, onNext, onPrev } = this.props
    const asset = assets[currentIndex]
    console.log(asset)
    return (
      <div className={styles.overlay}>
        {assets.length > 1 && 
          <button className={styles.btnNext} onClick={e => {
            if(onNext) onNext()
          }}>
            →
          </button>
        }
        
        {assets.length > 1 && 
          <button className={styles.btnPrev} onClick={e => {
            if(onPrev) onPrev()
          }}>
            ←
          </button>
        }

        <button className={styles.btnClose} onClick={e => {
          if(onClose) onClose()
        }}>
          ✕
        </button>
        
        <div className={styles.content}>
          {asset.fixed &&
            <Img fixed={asset.fixed} className={styles.image} fadeIn={true} objectFit="contain" />
          }

          {(asset.secure_url || asset.url) &&
            <AsyncImage src={asset.secure_url || asset.url} className={styles.image} />
          }
          
          <div className={styles.imageMeta}>
            <div><strong>{asset.title}</strong></div>
            <div>
              {asset.description}
            </div>
            {/*
            <div>Description</div>
            */}
          </div>
        </div>
      </div>
    )

  }
}
