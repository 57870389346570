import React from 'react'

export default class AsyncImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
    }
  }

  componentDidMount() {
    this.reloadImage()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if((prevProps.src != this.props.src) && this.state.loaded === true) {
      this.setState({ loaded: false })
      this.reloadImage()
    } 
  }

  reloadImage() {
    const img = new Image()
    img.onload = () => {
      this.setState({
        loaded: true
      })
    }

    img.onerror = () => {
      this.setState({
        error: true
      })
    }

    img.src = this.props.src
  }

  render() {
    if (this.state.error) {

      // Return image cannot load
      return <div>Image failed to load</div>


    } else if (!this.state.loaded) {

      // Return the loader
      return <div>
        <div className="loader-ring">
          <div></div><div></div><div></div><div></div>
        </div>
      </div>

    }
    return <img
      className={this.props.className}
      style={this.props.style}
      src={this.props.src}
      alt={this.props.alt} />
  }
}