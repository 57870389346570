import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import ImageCarousel from '../components/image-carousel-transform-group'
import Lightbox from '../components/lightbox'
import { mergeCloudinaryContext, addCloudinaryTransformPreset } from '../lib/util'
import Loadable from "@loadable/component"

const LoadableNewsSection = Loadable(() =>
  import('../components/news-section')
)

class RootIndex extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      currentImageIndex: 0
    }
  }

  showLightbox(index) {
    this.setState({ showLightbox: true, currentImageIndex: index })
  }

  getCarouselImages () {
    const carousels = get(this, 'props.data.allContentfulImageCarousel.edges')
    const mediaContext = get(this, 'props.data.allCloudinaryMedia.edges')
    var carouselImages = []
    if(carousels.length > 0) {
      carouselImages = carousels[0].node.images
      carouselImages = mergeCloudinaryContext(carouselImages, mediaContext)
      console.log('merged', carouselImages)
    }
    return carouselImages
  }

  render() {
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const news = get(this, 'props.data.allContentfulNews.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    // console.log('newsList', newsList)
    const carouselImages = this.getCarouselImages()
    const { showLightbox, currentImageIndex } = this.state

    //TODO: check if layout is defined

    return (
      <div>
        <Helmet>
          <title>{siteMetadata.title}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta name="keywords" content={siteMetadata.keywords} />
          <meta name="author" content={siteMetadata.author} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={siteMetadata.image} />
        </Helmet>
        <Header data={author.node} />

        <div className="only-desktop">
          <ImageCarousel images={carouselImages} 
            onImageClicked={(image, imageIndex) => {
              this.showLightbox(imageIndex)
            }} />
        </div>

        <div className="only-mobile">
          {carouselImages.map((media, index) => (
            <img 
              key={media.id} 
              src={addCloudinaryTransformPreset(media.secure_url, 'carousel_mobile') 
                    || (media.fluid || {}).src}
              style={{ marginBottom: '10px' }}
              onClick={e => { this.showLightbox(index) }}
              alt="site splash image by ponnapa prakkamakul" />
          ))}
        </div>

        <div className="hidden">
          {siteMetadata.description}
        </div>

        <div className="hidden"
          dangerouslySetInnerHTML={{
            __html: author.node.shortBio.childMarkdownRemark.html,
          }}
        />

        <div className="wrapper">
          <LoadableNewsSection news={news} />
        </div>

        <Footer data={author.node} />

        {showLightbox && 
          <Lightbox 
            assets={carouselImages} 
            currentIndex={currentImageIndex}
            onClose={() => {
              this.setState({ showLightbox: false })
            }}
            onNext={() => {
              this.setState({ currentImageIndex: (currentImageIndex + 1) % carouselImages.length })
            }}
            onPrev={() => {
              this.setState({ currentImageIndex: ((currentImageIndex+ carouselImages.length) - 1) % carouselImages.length })
            }} />
        }
      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
        keywords
        author
      }
    }

    allContentfulNews(filter: { node_locale: { eq: "en-US" } }, sort: { fields: [startDate], order: ASC }) {
      edges {
        node { 
          title
          description {
            json
          }
          image {
            public_id
            secure_url
          }
          startDate
          endDate
          link
        }
      }
    }

    allContentfulImageCarousel(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          title
          images {
            public_id
            secure_url
          }
        }
      }
    }

    allCloudinaryMedia(filter: {public_id: {glob: "PAINT/*/*"}}) {
      edges {
        node {
          public_id
          context {
            custom {
              alt
              caption
            }
          }
        }
      }
    }
    

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          instagram
          email
        }
      }
    }
  }
`
